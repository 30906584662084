import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby'
import Layout from '../components/Layout';
import Mapbox from '../mapbox'
import Calculate from '../util/calculate';

import { Select } from 'antd';

import './index.css';
import ImageBG from '/images/ecd.jpg';

import childrenD from '/images/ecd-1.png';
import children from '/images/ecd-4.png';
import children_help from '/images/ecd-5.png';
import money from '/images/tps-2.png';
import paper from '/images/oosc-3.png';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import TumbonAddr from '../util/TumbonAddr'
import RefDataDate from "../components/RefDataDate";
import ENG_Addrees from '../util/ENG_Addrees'
import useTranslation from '../hook/useTranslation'
import api from '../util/api';
import getProvinceName from '../util/getProvinceName'
import { set } from 'lodash';

const { Option } = Select;
let PROVINCE_Y2019 = [
  "กาญจนบุรี",
  "ขอนแก่น",
  "นครนายก",
  "นครราชสีมา",
  "น่าน",
  "พิษณุโลก",
  "ภูเก็ต",
  "ยะลา",
  "ระยอง",
  "ลำปาง",
  "สงขลา",
  "สุราษฎร์ธานี",
  "สุรินทร์",
  "สุโขทัย",
  "อำนาจเจริญ",
  "อุบลราชธานี",
  "เชียงใหม่",
  "แม่ฮ่องสอน",
]

let PROVINCE_Y2020 = [
  "กาญจนบุรี",
  "ขอนแก่น",
  "นครนายก",
  "นครราชสีมา",
  "น่าน",
  "พิษณุโลก",
  "ภูเก็ต",
  "มหาสารคาม",
  "ยะลา",
  "ระยอง",
  "ลำปาง",
  // "สงขลา",
  "สุราษฎร์ธานี",
  "สุรินทร์",
  "สุโขทัย",
  "อำนาจเจริญ",
  "อุบลราชธานี",
  "เชียงใหม่",
  "แพร่",
  "แม่ฮ่องสอน",
  "กรุงเทพมหานคร"
]

const PageECD = (props) => {
  const [T, setLang, lang] = useTranslation()
  const [initData, setInitData] = useState(null)
  const [data, setData] = React.useState({
    target2019: 0,
    target2020: 0,
    ecd_tun: 0,
    ecd_total: 0,
    percentY2019: 0,
    percentY2020: 0,
  });

  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const [translate, setTranslate] = useState(null);
  const is_Click = useRef(false);
  const [filter, setFilter] = React.useState({
    minView: 'country',
    view: 'country',
    year: "2563",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
    checkbox_side: []
  });

  useEffect(async () => {
    let is_login = api.isLogin().status
    let res_profile = await (is_login ? api.getProfileDb() : null)
    let res = await api.getInitData('ecd')
    if (res_profile) {
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId
        setInitData(res.data.filter(item => item.province_name === getProvinceName(pid)))
        setFilter({ ...filter, minView : "province",view: "province", province_name: getProvinceName(pid) })
      } else {
        setInitData(res.data)
      }
    }
  }, [])


  // useEffect(async () => {
  //   let res = await api.getInitData('ecd')
  //   setInitData(res.data)
  // }, [])

  useEffect(() => {
    if (initData) {
      let response = onProcessData({
        province: filter['province_name'],
        city: filter['city_name'],
        tumbon: filter['tumbon_name'],
        layer: filter.view
      }, convertMapContent(filter.view, initData.map(x => ({ ...x, total: 1 }))))
      if (!filter.province_name || filter.province_name !== 'ทั้งหมด') {
        if (filter.city_name !== null) {
        } else {
        }
      }

      let border_filter = response.data
      if (filter.checkbox_side.length > 0) {
        if (filter.view === 'country') {
          border_filter = response.data.filter(item => !filter.checkbox_side.includes(item.province))
          console.log(filter.checkbox_side, border_filter)
        } else if (filter.view === 'province') {
          border_filter = response.data.filter(item => !filter.checkbox_side.includes(item.city))
        } else if (filter.view === 'city') {
          border_filter = response.data.filter(item => !filter.checkbox_side.includes(item.tumbon))
        }
      }

      setDataMap([
        { id: 'border', layer: filter.view, data: border_filter },
      ])

      setData({
        target2019: initData.reduce((acc, value) => acc + value.year2562_sum_ecd, 0),
        target2020: initData.reduce((acc, value) => acc + value.year2563_sum_ecd, 0),
        ecd_tun: initData.reduce((acc, value) => acc + value.year2563_sum_ecd, 0),
        ecd_total: initData.reduce((acc, value) => acc + value.year2563_target_ecd, 0),
        percentY2019: (
          initData.reduce((acc, value) => acc + value.year2562_sum_ecd, 0) /
          initData.reduce((acc, value) => acc + value.year2563_sum_ecd, 0)
        ) * 100,
        percentY2020: 100,
      })
    }
  }, [filter, initData])

  const convertMapContent = (layer, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => {
      return {
        province: item,
        total_ecd: 0,
        target_ecd: 0,
        sum_ecd: 0,
      }
    });

    const city = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname))].map(item => {
      return {
        province: item.split('_')[0],
        city: item.split('_')[1],
        total_ecd: 0,
        target_ecd: 0,
        sum_ecd: 0,
      }
    });
    const tumbon = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => {
      return {
        province: item.split('_')[0], city: item.split('_')[1],
        tumbon: item.split('_')[2],
        total_ecd: 0,
        target_ecd: 0,
        sum_ecd: 0,
      }
    });

    data.forEach((item) => {
      let total = (item.province20)
      let count = (item.province20)
      if (layer === 'country') {
        let index = province.findIndex(ele => ele.province === item.province_name)
        if (province[index]) {
          province[index]['total_ecd'] += item[`year${filter.year}_total_ecd`]
          province[index]['target_ecd'] += item[`year${filter.year}_target_ecd`]
          province[index]['sum_ecd'] += item[`year${filter.year}_sum_ecd`]
          res = province
        }
      } else if (layer === 'province') {
        let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
        if (city[index]) {
          city[index]['total_ecd'] += item[`year${filter.year}_total_ecd`]
          city[index]['target_ecd'] += item[`year${filter.year}_target_ecd`]
          city[index]['sum_ecd'] += item[`year${filter.year}_sum_ecd`]
          res = city
        }
      } else if (layer === 'city') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (tumbon[index]) {
          tumbon[index]['total_ecd'] += item[`year${filter.year}_total_ecd`]
          tumbon[index]['target_ecd'] += item[`year${filter.year}_target_ecd`]
          tumbon[index]['sum_ecd'] += item[`year${filter.year}_sum_ecd`]
          res = tumbon
        }
        res = tumbon
      }
    })
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['sum_ecd'] / element['target_ecd'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['sum_ecd'] / result.map(item => item.sum_ecd).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })

    let data_color = [
      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.sum_ecd)), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.sum_ecd)), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.sum_ecd)), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.sum_ecd)
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  const onclickBorder = (layer, filterBorder) => {
    if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['year'] = filterBorder.year
        _filter['view'] = 'province'
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
        _filter['checkbox_side'] = []
      } else if (layer === 'city' && filterBorder.city) {
        _filter['year'] = filterBorder.year
        _filter['view'] = 'city'
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
        _filter['checkbox_side'] = []
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === _filter.minView) return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = 'ทั้งหมด'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }
  let listprovince = filter.year === '2563' ? PROVINCE_Y2020 : PROVINCE_Y2019
  return (
    <Layout Role={['public-login', 'partner', 'eef', 'partner_province']} backgroundImage={`url(${ImageBG})`}
      getLang={(translate, lang) => {
        setLang(lang)
      }}
    >
      <div>
        <div className='h-[120px]'></div>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">{T("ECD_Project_TITLE_1")}</h1>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">{T("ECD_Project_TITLE_2")}</h1>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">{T("ECD_Project_TITLE_3")}</h1>
        {/* <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">เด็กปฐมวัยยากจนในศูนย์พัฒนาเด็กเล็ก ในพื้นที่ 20 จังหวัด</h1> */}
        <div className="max-w-[1300px] 2xl:max-w-[1400px]  rounded-3xl m-auto p-8 bg-white bordered">
          <div className="font-bold text-2xl mb-2 text-[#0E3165]">{T("Project_Description")}</div>
          <p className="text-lg mb-2 font-baijam">{T("ECD_Project_Description")}</p>

          <div className="grid grid-cols-11 gap-6 w-full mt-8">
            <div className="col-span-12 md:col-span-6 lg:col-span-3 ">
              <div className="font-bold text-2xl mb-2 text-[#0E3165]">{T("Target_Group")}</div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[165px] p-1">
                <div className="px-2 py-4">
                  <div className='flex flex-row justify-around '>
                    <div className="flex flex-col m-2">
                      <img className="flex self-center" src={childrenD} alt="image for banner" width={'80px'} style={{}} />
                    </div>
                    <div className="flex-col self-center text-lg">
                      {
                        lang === 'EN' ?
                          <div className="flex-col">
                            Out-of-school children:<br /> ages 3-6 years old
                          </div>
                          :
                          <div className="flex-col">
                            เด็กและเยาวชนที่ไม่มีข้อมูล<br />ในระบบการศึกษา <br />ช่วงอายุ 3-6 ปี
                          </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 lg:col-span-8 ">
              <h3 className="font-bold text-2xl mb-2 text-[#0E3165]">{T("Type_Support")}</h3>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[300px] md:h-[165px] p-1">
                <div className="px-3">
                  <div className="grid grid-cols-12 w-full">
                    <div className="col-span-12 md:col-span-6 ">
                      <div className='flex flex-row w-full'>
                        <div className='w-1/5 mt-[16px]'>
                          <img className="flex self-center" src={money} alt="image for banner" width={'100%'} />
                        </div>
                        <div className="flex w-4/5 flex-col m-2">
                          <div className="font-bold text-2xl text-[#0E3165]">{T("ECD_400")}</div>
                          <div>{T("ECD_400_Description")}</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-6 ">
                      <div className='flex flex-row justify-around '>
                        <div className='w-[65px] mt-[16px]'>
                          <img className="flex self-center" src={paper} alt="image for banner" width={'100%'} />
                        </div>
                        <div className="flex flex-1 flex-col m-2">
                          <div className="font-bold text-2xl text-[#0E3165]">{T("ECD_Establish_Local_Mechanisms")}</div>
                          <div>{T("ECD_Establish_Local_Mechanisms_Description")}</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            <div className="col-span-11">
              <h3 className="font-bold text-2xl mb-2 mt-12 md:mt-0 text-[#0E3165]">{T("ECD_OUTCOMES_PROJECT_2563")}</h3>
              {/* <div className="grid grid-cols-11 gap-11 w-full"> */}
              <div className="grid grid-cols-11 gap-6 w-full">
                <div className="col-span-12 lg:col-span-4 flex flex-col">
                  <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[120px] p-1 mb-4">
                    <div className='flex flex-row justify-around '>
                      <div className="flex flex-col m-2">
                        <img className="flex self-center" src={children} alt="image for banner" width={'84px'} style={{}} />
                      </div>
                      {
                        lang === 'EN' ?
                          <div className="flex-col">
                            <div className="font-bold text-2xl m-0 text-green-500 text-right" >{`${data?.ecd_total?.toLocaleString('en-US')}`}</div>
                            <h3 className='m-0 text-xl text-right '>early childhood students</h3>
                          </div>
                          :
                          <div className="flex-col">
                            <h3 className='m-0 text-xl text-right '>เด็กปฐมวัยกลุ่มเป้าหมาย</h3>
                            <div className="font-bold text-2xl m-0 text-green-500 text-right" >{`${data?.ecd_total?.toLocaleString('en-US')}`}</div>
                            <h3 className='m-0 text-2xl text-right'>คน</h3>
                          </div>
                      }
                    </div>
                  </div>

                  <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[120px] p-1">
                    <div className='flex flex-row justify-around '>
                      <div className="flex flex-col m-2">
                        <img className="flex self-center" src={children_help} alt="image for banner" width={'84px'} style={{}} />
                      </div>
                      {
                        lang === 'EN' ?
                          <div className="flex-col">
                            <div className="font-bold text-2xl m-0 text-green-500 text-right" >{`${data?.ecd_tun?.toLocaleString('en-US')}`}</div>
                            <h3 className='m-0 text-xl text-right '>early childhood students who received scholarships </h3>
                          </div>
                          :
                          <div className="flex-col">
                            <h3 className='m-0 text-xl text-right '>เด็กปฐมวัยที่ได้รับทุน</h3>
                            <div className="font-bold text-2xl m-0 text-[#0E3165] text-right" >{`${data?.ecd_tun?.toLocaleString('en-US')}`}</div>
                            <h3 className='m-0 text-2xl text-right'>คน</h3>
                          </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="col-span-12 lg:col-span-7">
                  <div className="col-span-6">
                    <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[250px] p-1">
                      <div className="px-6 py-4">
                        <div className="flex flex-col">
                          <div className="font-bold text-xl mb-2 text-center">
                            {
                              lang === 'EN' ? 'early childhood students who received scholarships (Academic Year)' : 'จำนวนเด็กปฐมวัยที่ได้รับทุน (แบ่งตามปีการศึกษา)'
                            }
                          </div>
                        </div>
                        <div className='border-t-2 w-full h-[0.5px]'></div>
                        <div className="flex flex-col">

                          < div className="font-bold text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2563")}</div>

                          <div className="w-full flex flex-row content-center " >
                            <div
                              style={{ width: data.percentY2020 * 0.75 + '%' }}
                              className={`transition-all ease-out duration-1000 h-[18px] bg-green-700 relative rounded-md`}
                            />
                            < div className="font-bold text-md ml-2 text-bla ck-800">{`${data?.target2020?.toLocaleString('en-US')}`} {T("persons")}</div>
                          </div>

                          < div className="font-bold text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2562")}</div>
                          <div className="w-full flex flex-row content-center " >
                            <div
                              style={{ width: data.percentY2019 * 0.75 + '%' }}
                              className={`transition-all ease-out duration-1000 h-[18px] bg-green-500 relative rounded-md`}
                            />
                            < div className="font-bold text-md ml-2 text-bla ck-800">{`${data?.target2019?.toLocaleString('en-US')}`} {T("persons")}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="max-w rounded-xl overflow-hidden shadow-[0_0px_16px_rgba(17,17,26,0.1)] bg-white w-full mt-10 p-2">

            <header className="text-gray-600 body-font">
              <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <nav className="flex lg:w-1/5 flex-wrap items-center text-base md:ml-auto">
                </nav>
                <p className="w-3/5 items-center justify-center font-bold text-2xl mb-2  text-[#048967] text-center">
                  {T("ECD_MAP")}
                </p>
                <div className="lg:w-1/5 inline-flex lg:justify-end ml-5 lg:ml-0">
                  {
                    lang !== 'EN' &&
                    <button class="inline-flex items-center bg-emerald-500 border py-1 px-3 focus:outline-none hover:bg-emerald-700 rounded-full text-base mt-4 md:mt-0 text-white"
                      onClick={() => navigate('/fullmap-ecd')}>
                      สำรวจข้อมูลโครงการ
                      <svg fill="none" stroke="currentColor" s troke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                  }
                </div>

              </div >
            </header >

            <div className="w-full h-[1px] bg-gray-300 mb-4" > </div>

            <div className="grid grid-cols-11 gap-4 w-full">
              <div className="col-span-11 justify-center">
                <div className="flex flex-col p-0 m-0">
                  < div className="flex flex-col md:flex-row" >
                    <div >
                      <h5 className="text-sm m-0 ml-[9px]" >{T("year")} : </h5>
                      <Select
                        className='new-design-select'
                        showSearch
                        placeholder="ปีการศึกษา"
                        // defaultValue={filter.year}
                        value={filter.year}
                        style={{ width: 180, margin: 4 }}
                        onChange={(val) => {
                          setFilter({ ...filter, year: val })
                        }}
                      >
                        {
                          [
                            { value: '2563', text: T("Y2563") },
                            { value: '2562', text: T("Y2562") }
                          ]
                            .filter(x => x.value !== 'ไม่ระบุ')
                            .sort()
                            .map(x => <Option value={x.value}>{x.text}</Option>)
                        }

                      </Select>
                    </div>
                    <div>
                      <h5 className="text-sm m-0 ml-[9px]" >{T("province")} : </h5>
                      <Select
                        className='new-design-select'
                        placeholder="จังหวัด"
                        showSearch
                        value={filter.province_name}
                        onChange={(val) => {
                          if (val === 'ทั้งหมด') {
                            setFilter({
                              ...filter,
                              province_name: "ทั้งหมด",
                              view: 'country',
                              city_name: null,
                            })
                          } else {
                            setFilter({
                              ...filter,
                              province_name: val,
                              view: 'province',
                              city_name: null,
                            })
                          }
                        }}
                        style={{ width: 130, margin: 4, fontFamily: 'Kanit' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value={'ทั้งหมด'}>{ENG_Addrees('ทั้งหมด', lang)}</Option>
                        {
                          initData &&
                          [, ...new Set(initData.map(x => x.province_name))]
                            .filter(x => x !== 'ไม่ระบุ' && listprovince.includes(x))
                            .sort()
                            .map(x => <Option value={x}>{ENG_Addrees(x, lang)}</Option>)
                        }
                      </Select>
                    </div>
                    <div>
                      <h5 className="text-sm m-0 ml-[9px]" >{T("district")} : </h5>

                      <Select
                        className='new-design-select'
                        showSearch
                        value={filter.city_name}
                        placeholder={ENG_Addrees('เลือกอำเภอ', lang)} style={{ width: 130, margin: 4 }} disabled={filter.view === 'country'}
                        onChange={(val) => {
                          if (val === "ทั้งหมด") {
                            setFilter({
                              ...filter,
                            })
                          } else {
                            setFilter({
                              ...filter,
                              city_name: val,
                              view: 'city',
                            })
                          }

                        }}
                      >
                        {
                          initData &&
                          [...new Set(initData.filter(x => filter.province_name === x.province_name || filter.province_name === null).map(x => x.city_name))]
                            .sort()
                            .filter(x => x !== 'ไม่ระบุ')
                            .map(x => <Option value={x}>{ENG_Addrees(x, lang)}</Option>)
                        }
                      </Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-11">
                <div className="relative w-full h-full rounded overflow-hidden shadow-card bg-slate-500">
                  <Mapbox
                    id={'map-ำแก'}
                    filter={filter}
                    data={data_map}
                    className="fullmap-cct-container"
                    style={"border_basic"}
                    openSideTab={false}
                    notic_title={'จำนวนโรงเรียนในประเทศไทย'}
                    onclickBorder={onclickBorder}
                    lang={lang}
                  />
                  <div className={`absolute top-[90px] left-2 z-1}`}>
                    <div className='text-center justify-center mt-2 mx-auto'>
                      {filter.view !== filter.minView ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                        <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> {T("back")}
                      </button> : <></>}
                    </div>
                  </div>
                  <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
                    <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                      <span className='font-bold center mx-1 mb-2'>{T('ECD_SURVEYED')}</span>
                      <div className='flex'>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16  bg-[#1b5e20]`}>
                          </div>
                          <div class="font-bold text-center text-[10px] md:text-md">
                            {T("MAX")}
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16  bg-[#388e3c]`}>
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16  bg-[#66bb6a]`}>
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16  bg-[#a5d6a7]`}>
                          </div>
                          <div class="font-bold text-center text-[10px] md:text-md">
                            {T("MIN")}
                          </div>
                        </div>
                        <div className='ml-4  flex flex-col'>
                          <div class={`h-3 w-8 md:w-16  bg-[#94a3b8]`}>
                          </div>
                          <div class="font-bold text-center text-[10px] md:text-md">
                            {T("NO_DATA")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <RefDataDate
              title={T("ECD_REF")}
              subjectTitle={T('PROCESSED_APR_2565')}
            />

          </div >


        </div>
        <div className="bg-gray-200 h-[100px]">
        </div >
      </div>
    </Layout >
  )
}

export default PageECD
